<template>
  <div class="main_content">
    <top_view></top_view>
    <div class="main">
      <div class="content">
        <div class="tab-item">
          <div class="filter-box">
            <div class="filter-col">
              <div
                  @click="active = 0"
                  :class="{ 'filter-item': true, checked: active == 0 }"
              >
                <div class="filter-item-main">
                  <el-image :src="require('@/views/index/image/qylx.png')"></el-image>
                </div>
                <div class="filter-item__checked">
                  <el-image
                      :src="require('@/views/index/image/point.png')"
                      width="23"
                      height="8"
                  ></el-image>
                </div>
              </div>
            </div>
            <div class="filter-col">
              <div
                  @click="active = 1"
                  :class="{ 'filter-item': true, checked: active == 1 }"
              >
                <div class="filter-item-main">
                  <el-image :src="require('@/views/index/image/qyszd.png')"></el-image>
                </div>
                <div class="filter-item__checked">
                  <el-image :src="require('@/views/index/image/point.png')"></el-image>
                </div>
              </div>
            </div>
            <div class="filter-col">
              <div
                  @click="active = 2"
                  :class="{ 'filter-item': true, checked: active == 2 }"
              >
                <div class="filter-item-main">
                  <el-image :src="require('@/views/index/image/zchy.png')"></el-image>
                </div>
                <div class="filter-item__checked">
                  <el-image :src="require('@/views/index/image/point.png')"></el-image>
                </div>
              </div>
            </div>
            <div class="filter-col">
              <div
                  @click="active = 3"
                  :class="{ 'filter-item': true, checked: active == 3 }"
              >
                <div class="filter-item-main">
                  <el-image :src="require('@/views/index/image/zclb.png')"></el-image>
                </div>
                <div class="filter-item__checked">
                  <el-image :src="require('@/views/index/image/point.png')"></el-image>
                </div>
              </div>
            </div>
            <div class="filter-col">
              <div
                  @click="active = 4"
                  :class="{ 'filter-item': true, checked: active == 4 }"
              >
                <div class="filter-item-main">
                  <el-image :src="require('@/views/index/image/fbbm.png')"></el-image>
                </div>
                <div class="filter-item__checked">
                  <el-image :src="require('@/views/index/image/point.png')"></el-image>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-item-box">
            <div v-if="active == 0" class="filter-item-list">
              <el-tag
                  @click="onCompanyTypeItemClick(item)"
                  :effect="item.ischeck ? 'light' : 'plain'"
                  type="primary"
                  :class="{ 'filter-item-list-item': true, hover: item.ischeck }"
                  :key="item.id"
                  v-for="item in companyTypeList"
              >{{ item.labelName }}
              </el-tag
              >
            </div>
            <div v-if="active == 1" class="filter-item-list">
              <el-tag
                  @click="onCompanyAddrItemClick(item)"
                  :effect="item.ischeck ? 'light' : 'plain'"
                  type="primary"
                  :class="{ 'filter-item-list-item': true, hover: item.ischeck }"
                  :key="item.id"
                  v-for="item in companyAddrList"
              >{{ item.labelName }}
              </el-tag
              >
            </div>
            <div v-if="active == 2" class="filter-item-list">
              <el-tag
                  @click="onSupportTypeItemClick(item)"
                  :effect="item.ischeck ? 'light' : 'plain'"
                  type="primary"
                  :class="{ 'filter-item-list-item': true, hover: item.ischeck }"
                  :key="item.id"
                  v-for="item in supportTypeList"
              >{{ item.labelName }}
              </el-tag
              >
            </div>
            <div v-if="active == 3" class="filter-item-list">
              <el-tag
                  @click="onPolicyCategoryItemClick(item)"
                  :effect="item.ischeck ? 'light' : 'plain'"
                  type="primary"
                  :class="{ 'filter-item-list-item': true, hover: item.ischeck }"
                  :key="item.id"
                  v-for="item in policyCategoryList"
              >{{ item.labelName }}
              </el-tag
              >
            </div>
            <div v-if="active == 4" class="filter-item-list">
              <el-tabs v-model="deptInfo.choice">
                <el-tab-pane v-for="(item,k) in deptInfo.areaList" :label="item.name" :name="item.name" :key="k">
                  <el-tag
                      v-for="items in item.data"
                      @click="onDeptItemClick(items)"
                      :effect="items.ischeck ? 'light' : 'plain'"
                      type="primary"
                      :class="{ 'filter-item-list-item': true, hover: items.ischeck }"
                      :key="items.deptId"
                  >{{ items.deptName }}
                  </el-tag
                  >
                </el-tab-pane>
              </el-tabs>


            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="tab-item">
          <div class="filter-result">
            <div class="filter-result-left">筛选：</div>
            <div class="filter-result-center">
              <el-tag
                  type="primary"
                  :key="index"
                  v-for="(item, index) in filterResult"
                  @click="onFilterResultClose(item)"
              >{{ item.title }}
              </el-tag
              >
              <el-tag v-if="filterResult.length == 0" type="primary"
              >全部
              </el-tag
              >
            </div>
            <div class="filter-result-right">
              <div @click="onPublishClick()" class="fr-right-left">
                印发时间
                <el-image
                    style="vertical-align: middle; margin-left: 5px"
                    :src="
                    require(search.sort == 'asc'
                      ? '@/views/index/img/asc.png'
                      : '@/views/index/img/desc.png')
                  "
                ></el-image>
              </div>
              <div @click="onVolumeClick()" class="fr-right-center">
                浏览量
                <el-image
                    style="vertical-align: middle; margin-left: 5px"
                    :src="
                    require(search.volume == 'asc'
                      ? '@/views/index/img/asc.png'
                      : '@/views/index/img/desc.png')
                  "
                ></el-image>
              </div>
              <div class="fr-right-right">
                <el-checkbox
                    @change="isValidClick"
                    v-model="search.isValid"
                    label="仅显示有效条款"
                ></el-checkbox>
              </div>
            </div>
          </div>
          <policy_list :search-info="search" :get-list-key="getListKey"></policy_list>
        </div>
      </div>
    </div>
    <suspension_btn/>
  </div>
</template>

<script>
import policy_list from "@/views/index/home/compoents/policy_list";

export default {
  name: "policy_market",
  components: {policy_list},
  data: () => {
    return {
      activeName: "first",
      active: 0,
      companyTypeList: [],
      filterResult: [],

      company_type: [],
      company_addr: [],
      support_type: [],
      policy_category: [],
      companyAddrList: [],
      deptInfo: {
        areaList: [],
        choice: ""
      },
      supportTypeList: [],
      policyCategoryList: [],

      filterField: {
        company_type: true,
        company_addr: true,
        support_type: true,
        policy_category: true,
        dept: true,
      },

      getListKey: 0,
      search: {
        vagueName: null,
        isValid: false,
        validTrue: null,
        company_type: [],
        company_addr: [],
        support_type: [],
        policy_category: [],
        dept: [],
        sort: "desc",
        volume: "desc",
        page: 1,
        limit: 10
      },
    }
  },
  mounted() {
    this.getCompanyType();
    this.getPolicyCategory();
    this.getSupport();
    this.getAddress();
    this.getDept();
  },
  methods: {
    getAddress() {
      this.$http({
        url: this.$http.adornUrl("/app/labelcompanyaddress/selectList"),
        method: "get",
        params: this.$http.adornParams({}),
      }).then((data) => {
        this.companyAddrList = data.list;
        this.companyAddrList.forEach((item) => {
          item.ischeck = false;
        });
      });
    },
    getDept() {
      this.$http({
        url: this.$http.adornUrl("/app/dept/selectList"),
        method: "get",
        params: this.$http.adornParams({}),
      }).then((data) => {
        for (const k in data.list) {
          const v = data.list[k];
          v.ischeck = false;
          const dqKey = this.deptInfo.areaList.findIndex(item => item.name === v.areaName);
          if (dqKey === -1) {
            this.deptInfo.areaList.push({
              name: v.areaName,
              data: [v]
            });
          } else this.deptInfo.areaList[dqKey].data.push(v);
        }
        if (this.deptInfo.areaList.length > 0) this.deptInfo.choice = this.deptInfo.areaList[0].name;
      });

    },
    getSupport() {
      this.$http({
        url: this.$http.adornUrl("/app/labeldeptrelease/selectList"),
        method: "get",
        params: this.$http.adornParams({}),
      }).then((data) => {
        this.supportTypeList = data.list;
        this.supportTypeList.forEach((item) => {
          item.ischeck = false;
        });
      });
    },
    getPolicyCategory() {
      this.$http({
        url: this.$http.adornUrl("/app/labelpolicycategory/selectList"),
        method: "get",
        data: this.$http.adornParams({}),
      }).then((data) => {
        this.policyCategoryList = data.list;
        this.policyCategoryList.forEach((item) => {
          item.ischeck = false;
        });
      });
    },
    getCompanyType() {
      this.$http({
        url: this.$http.adornUrl("/app/labelcompanytype/selectList"),
        method: "get",
        params: this.$http.adornParams({}),
      }).then((data) => {
        this.companyTypeList = data.list;
        this.companyTypeList.forEach((item) => {
          item.ischeck = false;
        });
      });
    },
    isValidClick() {
      //重新查询
      this.getListKey++;
    },

    onFilterResultClose(tag) {
      this.filterResult.splice(this.filterResult.indexOf(tag), 1);
      if (tag.type == 1) {
        this.search.company_type.splice(
            this.search.company_type.indexOf(tag.title),
            1
        );
        this.companyTypeList.forEach((item) => {
          if (item.id == tag.id) {
            item.ischeck = !item.ischeck;
          }
        });
        this.search.companyType = this.search.company_type
            .map((item) => {
              return item;
            })
            .join("|");
      } else if (tag.type == 2) {
        this.search.company_addr.splice(
            this.search.company_addr.indexOf(tag.title),
            1
        );
        this.companyAddrList.forEach((item) => {
          if (item.id == tag.id) {
            item.ischeck = !item.ischeck;
          }
        });
        this.search.address = this.search.company_addr
            .map((item) => {
              return item;
            })
            .join("|");
      } else if (tag.type == 3) {
        this.search.support_type.splice(
            this.search.support_type.indexOf(tag.title),
            1
        );
        this.supportTypeList.forEach((item) => {
          if (item.id == tag.id) {
            item.ischeck = !item.ischeck;
          }
        });
        this.search.supportType = this.search.support_type
            .map((item) => {
              return item;
            })
            .join("|");
      } else if (tag.type == 4) {
        this.search.policy_category.splice(
            this.search.policy_category.indexOf(tag.title),
            1
        );
        this.policyCategoryList.forEach((item) => {
          if (item.id == tag.id) {
            item.ischeck = !item.ischeck;
          }
        });
        this.search.policyCategory = this.search.policy_category
            .map((item) => {
              return item;
            })
            .join("|");
      } else if (tag.type == 5) {
        const _t = this;
        this.deptInfo.areaList.forEach((item, k) => {
          item.data.forEach((items, ks) => {
            if (items.deptId === tag.id) _t.deptInfo.areaList[k].data[ks].ischeck = !items.ischeck;
          });
        });

        this.search.dept.splice(this.search.dept.indexOf(tag.title), 1);
        this.search.deptName = this.search.dept.map(item => item).join("|");

        this.getListKey++;
      }
    },
    onVolumeClick() {
      if (this.search.volume == "asc") {
        this.search.volume = "desc";
      } else {
        this.search.volume = "asc";
      }
      this.getListKey++;
    },
    onPublishClick() {
      if (this.search.sort == "asc") {
        this.search.sort = "desc";
      } else {
        this.search.sort = "asc";
      }
      this.getListKey++;
    },

    onCompanyTypeItemClick(item) {
      item.ischeck = !item.ischeck;
      let index = -1;
      this.filterResult.forEach((m, ind) => {
        if (m.title == item.labelName && m.type == 1) {
          index = ind;
        }
      });
      let d = {};
      d.title = item.labelName;
      d.type = 1;
      d.id = item.id;
      if (item.ischeck) {
        this.filterField.company_type = false;
        if (index < 0) {
          this.filterResult.push(d);
        }
        this.search.company_type.push(item.labelName);
      } else {
        if (index > -1) {
          this.filterResult.splice(index, 1);
        }
        this.search.company_type.splice(
            this.search.company_type.indexOf(item.labelName),
            1
        );
      }
      (this.search.companyType = this.search.company_type.map((item) => {
        return item;
      }).join("|"));
      this.getListKey++;
    },
    onCompanyAddrItemClick(item) {
      item.ischeck = !item.ischeck;
      let index = -1;
      this.filterResult.forEach((m, ind) => {
        if (m.title == item.labelName && m.type == 2) {
          index = ind;
        }
      });
      let d = {};
      d.title = item.labelName;
      d.type = 2;
      d.id = item.id;
      if (item.ischeck) {
        this.filterField.company_addr = false;
        if (index < 0) {
          this.filterResult.push(d);
        }
        this.search.company_addr.push(item.labelName);
      } else {
        if (index > -1) {
          this.filterResult.splice(index, 1);
        }
        this.search.company_addr.splice(
            this.search.company_addr.indexOf(item.labelName),
            1
        );
      }
      (this.search.address = this.search.company_addr
          .map((item) => {
            return item;
          })
          .join("|"))
      this.getListKey++;
    },
    onSupportTypeItemClick(item) {
      item.ischeck = !item.ischeck;
      let index = -1;
      this.filterResult.forEach((m, ind) => {
        if (m.title == item.labelName && m.type == 3) {
          index = ind;
        }
      });
      let d = {};
      d.title = item.labelName;
      d.type = 3;
      d.id = item.id;
      if (item.ischeck) {
        this.filterField.support_type = false;
        if (index < 0) {
          this.filterResult.push(d);
        }
        this.search.support_type.push(item.labelName);
      } else {
        if (index > -1) {
          this.filterResult.splice(index, 1);
        }
        this.search.support_type.splice(
            this.search.support_type.indexOf(item.labelName),
            1
        );
      }
      (this.search.supportType = this.search.support_type
          .map((item) => {
            return item;
          })
          .join("|"))
      this.getListKey++;
    },
    onPolicyCategoryItemClick(item) {
      item.ischeck = !item.ischeck;
      let index = -1;
      this.filterResult.forEach((m, ind) => {
        if (m.title == item.labelName && m.type == 4) {
          index = ind;
        }
      });
      let d = {};
      d.title = item.labelName;
      d.type = 4;
      d.id = item.id;
      if (item.ischeck) {
        this.filterField.policy_category = false;
        if (index < 0) {
          this.filterResult.push(d);
        }
        this.search.policy_category.push(item.labelName);
      } else {
        if (index > -1) {
          this.filterResult.splice(index, 1);
        }
        this.search.policy_category.splice(
            this.search.policy_category.indexOf(item.labelName),
            1
        );
      }
      (this.search.policyCategory = this.search.policy_category
          .map((item) => {
            return item;
          })
          .join("|"))
      this.getListKey++;
    },
    onDeptItemClick(item) {
      item.ischeck = !item.ischeck;
      let index = -1;
      this.filterResult.forEach((m, ind) => {
        if (m.title == item.deptName && m.type == 5) {
          index = ind;
        }
      });

      let d = {};
      d.title = item.deptName;
      d.type = 5;
      d.id = item.deptId;

      if (item.ischeck) {
        this.filterField.dept = false;
        if (index < 0) {
          this.filterResult.push(d);
        }
        this.search.dept.push(item.deptName);
      } else {
        if (index > -1) {
          this.filterResult.splice(index, 1);
        }
        this.search.dept.splice(this.search.dept.indexOf(item.deptName), 1);
      }
      (this.search.deptName = this.search.dept
          .map((item) => {
            return item;
          })
          .join("|"))
      this.getListKey++;
    },
  }
}

</script>

<style lang="scss" scoped>

.main_content{
  background: #f0f4f8;
}
.main {
  max-width: 1300px;
  margin: 0 auto;

  .top {
    padding-top: 96px;
    padding-bottom: 70px;

    .logo {
      width: 474px;
      margin: 0 auto;
    }
  }

  .search {
    .search-box {
      background-color: white;
      max-width: 900px;
      margin: 0 auto;
      border-radius: 4px;
      overflow: hidden;
      padding: 1px 0 1px 1px;

      ::v-deep .el-input__inner {
        height: 54px;
        line-height: 54px;
      }

      ::v-deep .el-input__inner::placeholder {
        font-size: 18px;
        color: #aaaaaa;
      }
    }
  }

  .content {
    padding: 20px 0 0 0;
    background-color: white;
    // border-radius: 10px;
    margin-top: 20px;

    ::v-deep .el-tabs__item {
      font-size: 24px;
      height: 50px;
      position: relative;
    }

    ::v-deep .el-tabs__active-bar {
      background-color: #1156a3;
    }

    ::v-deep .el-tabs__item.is-active {
      font-weight: bold;
      color: #1156a3;
    }

    ::v-deep .el-tabs__content {
      overflow: inherit;
    }

    .tab-item {
      position: relative;

      .search-box {
        position: absolute;
        width: 500px;
        top: -64px;
        right: 0;
        z-index: 10;
      }

      .filter-box {
        display: flex;
        position: relative;
        padding: 0 20px;

        .filter-col {
          margin: 0 5px;
          flex-grow: 1;
          flex-shrink: 0;
          width: 240px;
        }

        .filter-item {
          width: 100%;
          border-radius: 8px;
          position: relative;
          cursor: pointer;

          .filter-item-main {
            opacity: 0.5;
          }

          .filter-item__checked {
            display: none;
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            text-align: center;
            height: 8px;
          }
        }

        .filter-item.checked {
          .filter-item-main {
            opacity: 1;
          }

          .filter-item__checked {
            display: block;
          }
        }
      }

      .filter-item-box {
        margin-top: 10px;
        padding: 0 20px;

        .filter-item-list-item {
          // position: relative;
          // display:inline-block;
          padding: 6px 20px;
          font-size: 16px;
          height: auto;
          // line-height: 1;
          margin: 0 5px 12px;
          cursor: pointer;
          // z-index: 1;
          // border-radius: 4px;
          // background-color: white;
        }

        .filter-item-list-item:hover {
          background-color: #d9ecff;
        }
      }

      .filter-result {
        display: flex;
        width: 100%;
        margin-top: 0px;
        padding: 0 20px 8px;
        border-bottom: solid 1px #dddddd;

        .filter-result-left {
          flex-shrink: 0;
        }

        .filter-result-center {
          flex-grow: 1;
          flex-shrink: 0;
          overflow: hidden;
          width: 0;
          white-space: nowrap;

          .el-tag {
            margin-right: 10px;
            cursor: pointer;
          }
        }

        .filter-result-right {
          flex-shrink: 0;
          display: flex;
          color: #888888;
          line-height: 1;

          .fr-right-left {
            margin: 0 5px;
            padding-top: 5px;
            cursor: pointer;
          }

          .fr-right-center {
            margin: 0 5px;
            padding-top: 5px;
            cursor: pointer;
          }

          .fr-right-right {
            padding-top: 3px;
            margin: 0 5px 0 20px;
          }
        }
      }

      .policy-list {
        margin-top: 10px;

        .policy-item:hover {
          background-color: #f5f5f5;
        }

        .policy-item {
          padding: 10px 20px 20px;
          display: flex;
          border-bottom: solid 1px #e5e5e5;
          cursor: pointer;
          align-items: center;

          .item_btn {
            width: 144px;
            height: 48px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.blue {
              background: #1156A3;
              color: #FFFFFF;
            }

            &.white {
              border: 1px solid #1156A3;
              background: #fff;
              color: #1156A3;
            }
          }

          .item-left {
            flex-grow: 1;
            padding-right: 20px;
            line-height: 1.5;
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 0;

            .title {
              font-size: 26px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .tag {
              margin-top: 10px;
              width: 100%;
              overflow: hidden;

              .el-tag {
                margin-right: 10px;
                display: inline-block;
              }
            }

            .info {
              display: flex;
              font-size: 16px;
              color: #888;
              margin-top: 10px;

              .el-image {
                vertical-align: middle;
              }

              .info-center {
                margin-left: 30px;
                max-width: 600px;
              }

              .info-right {
                text-align: left;
                margin-left: 30px;
              }
            }
          }

        }
      }
    }
  }
}
</style>
